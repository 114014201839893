import _AnimatorParent2 from "./components/AnimatorParent";
var exports = {};
exports.__esModule = true;
exports["default"] = void 0;

var _AnimatorParent = _interopRequireDefault(_AnimatorParent2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = _AnimatorParent["default"];
exports["default"] = _default;
export default exports;