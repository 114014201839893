var exports = {};
exports.__esModule = true;
exports["default"] = void 0;

var shouldFlipAnimation = function shouldFlipAnimation(sequence, _ref) {
  var enter = _ref.enter,
      exit = _ref.exit;
  var isFlip = !!sequence;

  if (enter) {
    if (sequence === "default" || sequence === "flip") {
      isFlip = false;
    }
  } else if (exit) {
    if (sequence === "default" || sequence === "reverse-flip") {
      isFlip = false;
    }
  }

  return isFlip;
};

var _default = shouldFlipAnimation;
exports["default"] = _default;
export default exports;