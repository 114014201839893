import _Animator2 from "./components/Animator";
var exports = {};
exports.__esModule = true;

var _Animator = _interopRequireDefault(_Animator2);

exports.Animator = _Animator["default"];

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

export default exports;
export const __esModule = exports.__esModule,
      Animator = exports.Animator;