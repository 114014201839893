import * as _react3 from "react";

var _react2 = "default" in _react3 ? _react3.default : _react3;

import _initTranslateProp2 from "./init-translate-prop";
import _constants2 from "../constants/constants";
var exports = {};
exports.__esModule = true;
exports["default"] = void 0;

var _react = _interopRequireDefault(_react2);

var _initTranslateProp = _interopRequireDefault(_initTranslateProp2);

var _constants = _constants2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var getDataOrDefault = function getDataOrDefault(arr, value, name) {
  return arr.indexOf(value) > -1 ? value : _constants.propsDefault[name];
};

var getScale = function getScale(scale, timing) {
  return ["small", "medium"].indexOf(timing) > -1 ? 0.75 : 0.9;
};

var getDelay = function getDelay(value) {
  var delay = typeof value === "object" ? value : {
    enter: value,
    exit: value
  };
  return {
    enter: delay.enter ? delay.enter : 0,
    exit: delay.exit ? delay.exit : 0
  };
};

var propsMap = {
  timing: function timing(value, name) {
    return getDataOrDefault(_constants.timings, value, name);
  },
  sequence: function sequence(value, name) {
    return value && getDataOrDefault(_constants.sequences, value, name);
  },
  children: function children(_children) {
    return _react["default"].Children.toArray(_children);
  },
  opacity: function opacity(_opacity) {
    return !!_opacity;
  },
  scale: function scale(_scale, _, _ref) {
    var timing = _ref.timing;
    return !!_scale && getScale(_scale, timing);
  },
  height: function height(_height) {
    return !!_height && _height;
  },
  width: function width(_width) {
    return !!_width && _width;
  },
  translate: function translate(_translate) {
    return _translate && (0, _initTranslateProp["default"])(_translate);
  },
  delay: function delay(value) {
    return !!value && getDelay(value);
  },
  childStyle: function childStyle(value) {
    return !!value && value;
  },
  childClassName: function childClassName(value) {
    return !!value && value;
  },
  show: function show(value, _, _ref2) {
    var debug = _ref2.debug;
    return !!debug || value;
  }
};

var getPropData = function getPropData(name, value, props) {
  var getPropMethod = propsMap[name];
  return getPropMethod && getPropMethod(value, name, props);
};

var getData = function getData(props) {
  return Object.keys(propsMap).reduce(function (data, propName) {
    var _objectSpread2;

    return _objectSpread((_objectSpread2 = {}, _objectSpread2[propName] = getPropData(propName, props[propName], props), _objectSpread2), data);
  }, {});
};

var formatProps = function formatProps(props) {
  return getData(props);
};

var _default = formatProps;
exports["default"] = _default;
export default exports;