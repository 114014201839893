import _Animator2 from "../Animator.scss";
var exports = {};
exports.__esModule = true;
exports.validChildProps = exports.translateMap = exports.transitionClassNames = exports.timings = exports.timingMap = exports.sequences = exports.propsDefault = exports.percentages = exports.directions = exports.animationProps = void 0;

var _Animator = _interopRequireDefault(_Animator2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var top = "top";
var bottom = "bottom";
var left = "left";
var right = "right";
var micro = "micro";
var small = "small";
var medium = "medium";
var large = "large";
var slow01 = "slow01";
var slow02 = "slow02";
var medium01 = "medium01";
var medium02 = "medium02";
var fast01 = "fast01";
var fast02 = "fast02";
var percentages = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
exports.percentages = percentages;
var timings = [micro, small, medium, large, fast01, fast02, medium01, medium02, slow01, slow02];
exports.timings = timings;
var directions = [top, bottom, left, right];
exports.directions = directions;
var sequences = ["default", "flip", "reverse", "reverse-flip"];
exports.sequences = sequences;
var timingMap = {
  micro: 120,
  small: 150,
  medium: 200,
  large: 300,
  fast01: 100,
  fast02: 150,
  medium01: 200,
  medium02: 300,
  slow01: 450,
  slow02: 600
};
exports.timingMap = timingMap;
var translateMap = {
  to: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right"
  },
  size: percentages
};
exports.translateMap = translateMap;
var propsDefault = {
  duration: timingMap[large],
  timing: large,
  sequence: "default",
  sequenceDelay: 80,
  translate: {
    to: translateMap.to.top,
    size: percentages[5]
  }
};
exports.propsDefault = propsDefault;
var animationProps = ["opacity", "scale", "height", "translate", "width"];
exports.animationProps = animationProps;
var validChildProps = ["debug", "sequence", "timing"].concat(animationProps);
exports.validChildProps = validChildProps;
var transitionClassNames = {
  enter: _Animator["default"].enter,
  appear: _Animator["default"].enter,
  enterActive: _Animator["default"].enterActive,
  appearActive: _Animator["default"].enterActive,
  exit: _Animator["default"].exit,
  exitActive: _Animator["default"].exitActive
};
exports.transitionClassNames = transitionClassNames;
export default exports;