var exports = {};
exports.__esModule = true;
exports["default"] = void 0;

var getPaddingSize = function getPaddingSize(padding) {
  var data = new RegExp(/(\d+)px/g).exec(padding);
  return data && data[1] ? parseInt(data[1]) : 0;
};

var getDimensionsByNode = function getDimensionsByNode(node) {
  var scrollHeight = node.scrollHeight,
      scrollWidth = node.scrollWidth,
      paddingRight = node.style.paddingRight;
  return {
    height: scrollHeight,
    width: scrollWidth + getPaddingSize(paddingRight)
  };
};

var isNumber = function isNumber(prop) {
  return typeof prop === "number";
};

var isFunction = function isFunction(prop) {
  return typeof prop === "function";
};

var executeFunction = function executeFunction(callback, node) {
  var response = callback(node);

  if (!isNumber(response)) {
    throw new Error("height/width function must return a number!");
  }

  return response;
};

var getDimensions = function getDimensions(node, _ref) {
  var height = _ref.height,
      width = _ref.width;
  var nodeDimensions = node ? getDimensionsByNode(node) : {
    height: 0,
    width: 0
  };
  return {
    height: isNumber(height) ? height : isFunction(height) ? executeFunction(height, node) : nodeDimensions.height,
    width: isNumber(width) ? width : isFunction(width) ? executeFunction(width, node) : nodeDimensions.width
  };
};

var _default = getDimensions;
exports["default"] = _default;
export default exports;