var exports = {};
exports.__esModule = true;
exports["default"] = void 0;

var fillSegment = function fillSegment(segment) {
  if (segment === void 0) {
    segment = {};
  }

  if (typeof segment === "string") {
    segment = {
      direction: segment,
      size: "100%"
    };
  }

  return {
    direction: segment.direction ? segment.direction : "top",
    size: segment.size ? segment.size : "100%"
  };
};

var initTranslateProp = function initTranslateProp(translate) {
  if (typeof translate === "boolean") {
    translate = {
      enter: "top",
      exit: "top"
    };
  } else if (typeof translate === "string") {
    translate = {
      enter: translate,
      exit: translate
    };
  } else if (typeof translate !== "object") {
    translate = {};
  }

  return {
    enter: fillSegment(translate.enter),
    exit: fillSegment(translate.exit),
    useSameTransition: !!translate.useSameTransition
  };
};

var _default = initTranslateProp;
exports["default"] = _default;
export default exports;